import * as State from './state';
import { Actions } from '../actions/dataCollectionDisclosureWarning';

/**
 * The default initial state
 */
export const dataCollectionDisclosureWarningInitial: State.DataCollectionDisclosureWarning = {
    showDataCollectionDisclosureWarningModal: false,
    onProceedButtonClick: undefined
};

/**
 * Reducer for the data collection disclosure warning
 * @param state The current state
 * @param action The action to apply
 */
export const DataCollectionDisclosureWarningReducer =
    (state: State.DataCollectionDisclosureWarning = dataCollectionDisclosureWarningInitial, action: Actions.DataCollectionDisclosureWarningActions): State.DataCollectionDisclosureWarning => {
        switch (action.type) {
            case Actions.DataCollectionDisclosureWarningShowType:
                return {
                    ...state,
                    showDataCollectionDisclosureWarningModal: action.payload.show,
                    onProceedButtonClick: action.payload.onProceedButtonClick
                };
            default:
                return state;
        }
    };