import React from 'react';
import ReactModal from 'react-modal';
import * as Containers from '../redux-containers';
import { CmsText } from '../../misc/cms-text';
import { EmptyObject } from '../../misc/common';
import { CmsService } from '../../services/cms-service';
import { Actions } from '../../actions/dataCollectionDisclosureWarning';
import HtmlReactParser from 'html-react-parser';
import sanitizeHtml from 'sanitize-html'

interface DataCollectionDisclosureModalStateProps {
    isOpen: boolean;
    allText: any;
    onConfirmCallback?: () => void;
}

interface DataCollectionDisclosureModalDispatchProps {
    close: () => void;
    open: () => void;
}

export type DataCollectionDisclosureModalProps = DataCollectionDisclosureModalStateProps & DataCollectionDisclosureModalDispatchProps;

class DataCollectionDisclosureModal extends React.Component<DataCollectionDisclosureModalProps> {
    private buttonRef = React.createRef<HTMLButtonElement>();
    
    constructor(props: DataCollectionDisclosureModalProps) {
        super(props);
    }

    public componentDidMount() {
        ReactModal.setAppElement('body');
    }

    private handleProceed = () => {
        if (this.props.onConfirmCallback) {
            this.props.onConfirmCallback();
        }
        this.props.close();
    }

    private handleCancel = () => {
        this.props.close();
    }

    public render() {
        const cmsText = new CmsText(this.props.allText, 'dataCollectionDisclosure', 'dashboard.proctoring.dataCollectionDisclosure');
        const title = cmsText.get('title', 'Proctoring Data Disclosure');
        const body = cmsText.get('body', 'Body text is missing');
        const proceedButton = cmsText.get('actions.proceed', 'Proceed');
        const cancelButton = cmsText.get('actions.cancel', 'Cancel');
        const isOpen = !!this.props.isOpen;
        const cleanBody = HtmlReactParser(
            sanitizeHtml(
                body, 
                { 
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['ol', 'ul', 'li', 'p'])
                }
            )
        );
        const noHtmlBody = sanitizeHtml(body, { allowedTags: [] });

        return (
            <ReactModal
                isOpen={isOpen}
                className={CmsService.GetSiteFontStyle() + ' data-collection-disclosure-modal'}
                overlayClassName="modal-overlay"
                contentLabel={title + ' ' + noHtmlBody}
                ariaHideApp={false}
                role="alert"
            >
                <div className="modal-header" aria-label={title}>{title}</div>
                <div className="modal-body" aria-label={noHtmlBody}>{cleanBody}</div>
                <div className="modal-footer">
                    <button 
                        onClick={this.handleCancel} 
                        className="modal-cancel-button" 
                        ref={this.buttonRef} 
                        aria-label={cancelButton}>
                        {cancelButton}
                    </button>
                    <button 
                        onClick={this.handleProceed} 
                        className="modal-proceed-button" 
                        aria-label={proceedButton}>
                        {proceedButton}
                    </button>
                </div>
            </ReactModal>
        );
    }
}

export default Containers.createStateAndDispatchWithProps<DataCollectionDisclosureModalStateProps, DataCollectionDisclosureModalDispatchProps, EmptyObject>(
    DataCollectionDisclosureModal,
    (state) => ({
        isOpen: state.dataCollectionDisclosureWarning.showDataCollectionDisclosureWarningModal,
        onConfirmCallback: state.dataCollectionDisclosureWarning.onProceedButtonClick,
        allText: state.language.alltext
    }),
    (dispatch) => ({
        close: () => dispatch<any>(Actions.showDataCollectionDisclosureWarning({show: false})),
        open: () => dispatch<any>(Actions.showDataCollectionDisclosureWarning({show: true}))
    })
);
