import React from 'react';
import { EmptyObject } from '../misc/common';
import { RouterMetadata } from '../components/higher-order/with-router';
import AssessmentPlayer from '../components/proctoring/assessmentPlayer';
/**
 * Renders the entire Proctored Launch page.
 */

/**
 * Props for the Proctored Launch, values passed in via React Router
 */
type ProctoredLaunchPageProps = {
  /**
   * React Router meta data such as location, params, navigation
   */
  router: RouterMetadata
};

export default class ProctoredLaunchPage extends React.Component<ProctoredLaunchPageProps, EmptyObject> {
  render() {
    return  <AssessmentPlayer router={this.props.router}/>;
  }
}