import { AssessmentModel, AssessmentStatus } from '../models/assessment';
import { getLaunchAssessmentUrl } from '../services/candidate-service';
import { logErrorWithAlert } from './error';
import { CmsText } from './cms-text';

export function launchAssessment(assessment: AssessmentModel, siteLanguageId: number, text: CmsText): Promise<any> {
    // The assessment must be taken in it's currently set language, if not defined then must use the site language.
    const assessmentId = assessment.assessmentId;
    const languageId = (assessment.languageId > 0) ? assessment.languageId : siteLanguageId;

    // If the assessment is proctored and the proctoring is local, then we need to redirect 
    // to the proctoring page with an iframe.
    if (assessment.isProctored && assessment.isProctoringLocal) {
        const proctoredLaunchUrl = `/proctored-launch/${assessmentId}/${languageId}`;
        history.pushState({ assessmentId, languageId }, 'Assessment Player', proctoredLaunchUrl); 
        history.go();
        return Promise.resolve();
    } else {
        return getLaunchAssessmentUrl(assessmentId, languageId).then(result => {
                if (result.status === AssessmentStatus.Completed) {
                    logErrorWithAlert(text.get('canNotLaunchError', 'Cannot launch assessment'), 'Unable to launch assessment, assessment complete');
                } else if (!result.launchUrl) {
                    logErrorWithAlert(text.get('canNotLaunchError', 'Cannot launch assessment'), 'Unable to launch assessment, no url found');
                } else {
                    location.href = result.launchUrl;
                }
            }).catch(exception => {
                logErrorWithAlert(text.get('canNotLaunchError', 'Cannot launch assessment'), 'Error launching assessment', exception);
            });
    }
    
}
