import 'isomorphic-fetch';
import { getObject, buildActionUrlFromParts } from './api-service';
import { Language } from '../models/language';

/* Service to interact with CMS */
export namespace CmsService {
    const cmsManifest = process.env.REACT_APP_CMS_Manifest;
    const cmsCachedUrl = process.env.REACT_APP_CDN_CACHED_URL;
    const cmsRestEndPoint = 'SiteText';
    const cmdFullEndPoint = buildActionUrlFromParts(process.env.REACT_APP_CDN_API_URL, process.env.REACT_APP_API_VERSION, cmsRestEndPoint);
    const VietnameseLanguageId = 41;
    const sessionLanguageCode = "KF_LANGUAGE_CODE";

    /** Method to get all the text(from CMS) for login site in the passed in language
     * @export
     * @param {number} languageId   
     * @returns {Promise<any>} 
     */
    export function GetSiteText(languageId: number, preview: boolean, brandingName: string | undefined): Promise<any> {
        // On test / staging / production, CloudFront the text is requested via CloudFront which will then forward the request
        // to the server and caches the results. Subsequent requests with the same URL will be served from the cache
        if (cmsCachedUrl !== undefined && cmsCachedUrl.length > 0) {
            return getObject(cmsCachedUrl + '/' + languageId + '.json', (json) => <any> json, { branding: brandingName, preview: preview })
                .then(j => {
                    let siteText = j.cmsText;
                    siteText.allLanguages = j.allLanguages;
                    return siteText;
                });
        }

        let params = {
            languageId: languageId,
            manifest: cmsManifest,
            type: 'json',
            branding: brandingName,
            preview: preview
        };

        return getObject(cmdFullEndPoint, (json) => <any> json, params, { authorize: false })
            .then(j => {
                let siteText = j.cmsText;
                siteText.allLanguages = j.allLanguages;
                return siteText;
            });
    }

    /** Method to get the site language set in session storage
     * @returns {number} The site language Id
     */
    export function GetSiteLanguage(): number {
        let language = sessionStorage.getItem('language');
        return !language ? GetDefaultLanguage() : parseInt(language, 10);
    }

    /** Method to get the default language for the site
     * @returns {number} The site language Id
     */
    export function GetDefaultLanguage(): number {
        let defaultLanguage: string = process.env.REACT_APP_DEFAULT_LANGUAGES;

        return parseInt(defaultLanguage, 10);
    }

    /** Method to get the default language code for the site
     * @returns {string} The site language code
     */
    export function GetDefaultLanguageCode(): string {
        // The default language code needs to be added to the environment variable as below:
        // let defaultLanguageCode: string = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE;
        // For current scenario setting the default language code to 'en-US'
        let defaultLanguageCode: string = 'en-US';
        return defaultLanguageCode;
    }

    /** Store the selected language in session storage    
     * @param {number} languageId LanguageId to save in session storage
     */
    export function SetSiteLanguage(languageId: number): void {
        sessionStorage.setItem('language', String(languageId));
    }

    /** Store the selected language code in session storage    
     * @param {string} languageCode LanguageCode to save in session storage
     */
    export function SetSiteLanguageCode(languageCode: string): void {
        sessionStorage.setItem(sessionLanguageCode, languageCode)
    }

    /** Method to get the site language code set in session storage
     * @returns {string} The site language code
     */
    export function GetSiteLanguageCode(): string {
        let languageCode = sessionStorage.getItem(sessionLanguageCode)
        return languageCode || GetDefaultLanguageCode()
    }

    /**
     * Returns style with font of the site
     * @returns style with font of the site
     */
    export function GetSiteFontStyle(): string {
        return GetSiteLanguage() !== VietnameseLanguageId ? 'body' : 'bodyArial';
    }

    export function GetLanguageCode(languages: any, languageId: number) {
        return (languages as Language[]).filter(l => l.id === languageId)[0];
    }
}