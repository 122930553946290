import React from 'react';
import * as Containers from '../redux-containers';
import { CmsText } from '../../misc/cms-text';
import { DispatchProp } from 'react-redux';
import { AsyncButton } from '../common/async-button';
import { getReport, launchDynamicFeedback } from '../../services/report-service';
import { logErrorWithAlert } from '../../misc/error';
import { ReportModel } from '../../models/report';
import { FormattedDate } from 'react-intl';
import { Actions } from '../../actions/dynamicFeedbackOverlay';
import { CmsService } from '../../services/cms-service';
import { ReportFormats } from '../../enums/reportFormats';
import { NoJsxElement } from '../../misc/common';
import { Language } from '../../models/language';

interface DownloadReportOwnProps {
    /**
     * The report to launch.
     */
    reports: ReportModel[];
}

interface DownloadReportStateProps {
    /**
     * Component Text
     */
    text: CmsText;
    /**
     * All languages
     */
    languages: Language[];
}

interface DynamicFeedbackDispatchProps {
    openDynamicFeedback: (r: string) => void;
}
type DownloadReportAllProps = DownloadReportOwnProps & DownloadReportStateProps & DynamicFeedbackDispatchProps & DispatchProp<any>;

/**
 * Renders a launch assessment component that starts the assessment in the Reflex system.
 * @param props The properties to pass to the component
 */
export class DownloadReport extends React.Component<DownloadReportAllProps> {
    constructor(props: DownloadReportAllProps) {
        super(props);
        this.download = this.download.bind(this);
    }

    async download(reportType: string, projectId: number, reportLanguage: number, reportOptions?: string): Promise<any> {
        try {
            return await getReport(projectId, reportType, reportLanguage, reportOptions);
        } catch (exception: any) {
            if (exception?.status === 504) {
                logErrorWithAlert(
                    this.props.text.get('gatewayTimeout', 'Sorry, your request is taking longer than expected. Please try again later. If the issue continues, contact support for help.'),
                    'Gateway Timeout',
                    exception
                );
            } else {
                logErrorWithAlert(
                    this.props.text.get('canNotDownloadReport', 'Cannot download report'),
                    'Error downloading report',
                    exception
                );
            }
        }
    }
    
    async openDynamicFeedbackOverlay(projectId: number, reportType: string, reportLanguageId: number): Promise<any> {
        const { text, languages, openDynamicFeedback } = this.props;
        const languageCode = CmsService.GetLanguageCode(languages, reportLanguageId).code

        try {
            const dynamicFeedbackUrl = await launchDynamicFeedback(
                projectId, 
                reportType, 
                languageCode
            );
            console.log("dynamicFeedbackUrl:", dynamicFeedbackUrl);
            openDynamicFeedback(dynamicFeedbackUrl);
            /**
             * Hide dashboard page scrollbar
             */
            document.body.style.overflow = 'hidden';
        } catch (exception: any) {
            if (exception?.status === 504) {
                logErrorWithAlert(
                    text.get('gatewayTimeout', 'Sorry, your request is taking longer than expected. Please try again later. If the issue continues, contact support for help.'),
                    'Gateway Timeout',
                    exception
                );
            } else {
                logErrorWithAlert(
                    text.get('cannotLaunchDynamicFeedback', 'Cannot launch Dynamic Feedback'),
                    'Error launching Dynamic Feedback',
                    exception
                );
            }
        }
    }

    renderReportLinks(reports: ReportModel[]) {
        return reports.map((report, index) => {
            const { 
                reportType, 
                projectId, 
                languageId, 
                reportOptions,
                format
            } = report;
            const isPdf = format === ReportFormats.PDF;
            const isDynamicFeedback = format === ReportFormats.DYNAMIC_FEEDBACK 
                                    || format === ReportFormats.DYNAMIC_FEEDBACK_AI;
            const key = `${reportType}_${projectId}_${languageId}_${format}`;
            return (
                <div key={key} >
                    {isPdf && <AsyncButton className="download-reports-button" clickEvent={() => this.download(reportType, projectId, languageId, reportOptions)} >
                        <span className="download-reports-icon" />
                        <span className="download-reports-label">{ this.props.text.get('downloadPDF', 'DOWNLOAD PDF') }</span>
                    </AsyncButton>}
                    {isDynamicFeedback && <AsyncButton className="download-reports-button" clickEvent={() => this.openDynamicFeedbackOverlay(projectId, reportType, languageId)} >
                        <span className="video-icon" />
                        <span className="download-reports-label">{this.props.text.get('dynamicFeedback', 'DYNAMIC FEEDBACK')}</span>
                    </AsyncButton>}
                </div>
            );
        });
    }

    render() {

        const { reports } = this.props;

        if (!reports || reports.length === 0) {
            return NoJsxElement;
        }

        const rootReport = reports.find((report) => !report.parentBlendedReportId);

        if (!rootReport) {
            return NoJsxElement;
        }

        return (
            <div className="download-reports-wrapper" key={`${rootReport.reportType}_${rootReport.projectId}`}>
                <div className="download-reports-text-container">
                    <span className="download-reports-name">{rootReport.name}:</span>
                    <div className="download-reports-date">
                        <FormattedDate value={rootReport.date} day="numeric" month="long" year="numeric" />
                    </div>
                </div>
                <div className="download-reports-icon-container">
                    { this.renderReportLinks(reports) }
                </div>
            </div>
        );
    }
}

 export default Containers.createStateAndDispatchWithProps<DownloadReportStateProps, DynamicFeedbackDispatchProps, DownloadReportOwnProps>(
    DownloadReport,
    (state) => ({
        text: new CmsText(state.language.alltext, 'DownloadReport', 'dashboard.downloadReport'),
        languages: state.language.alltext.allLanguages
    }),
    (dispatch) => ({
        openDynamicFeedback: (url) => dispatch<any>(Actions.showDynamicFeedbackOverlay({show: true, dynamicFeedbackLaunchURL: url}))
    })
);