export default {
    Proctoring: {
        Statuses: {
            STARTED: "started",
            STOPPED: "stopped",
            PAUSED: "paused"
        },
        MessageEventActions: {
            PAUSE: "pause-proctoring",
            STOP: "stop-proctoring"
        },
        SessionStorageKeys: {
            PROCTORED_ASSESSMENT_URL: "proctoredAssessmentUrl"
        }
    }
}