import { Action, createAction } from './actions';

interface DataCollectionDisclosureWarningActionPayload {
    show: boolean;
    onProceedButtonClick?: () => void;
}

export namespace Actions {
    export const DataCollectionDisclosureWarningShowType = 'DATA_COLLECTION_DISCLOSURE_WARNING_DIALOG_SHOW';
    export type DataCollectionDisclosureWarningShowAction = Action<typeof DataCollectionDisclosureWarningShowType, DataCollectionDisclosureWarningActionPayload>;
    export function showDataCollectionDisclosureWarning(payload: DataCollectionDisclosureWarningActionPayload): DataCollectionDisclosureWarningShowAction {
        return createAction(DataCollectionDisclosureWarningShowType, payload);
    }
    // Export all of the actions from this file
    export type DataCollectionDisclosureWarningActions = DataCollectionDisclosureWarningShowAction;
}