import React from 'react';
import AuthenticatePage from './pages/authenticate';
import DashboardPage from './pages/dashboard';
import DashboardPreview from './pages/dashboardPreview';
import withAuthentication from './components/higher-order/with-authentication';
import withCandidateData from './components/higher-order/with-candidate-data';
import withRouter from './components/higher-order/with-router';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BrandingWrapper from './components/branding/branding';
import 'react-toastify/dist/ReactToastify.min.css';
import withCms from './components/higher-order/with-cms';
import FontResize from './services/font-resize.service';
import SanctionedPage from './pages/sanctioned';
import SessionTimeOutPage from "./pages/sessionTimeoutPage";
import ProctoredLaunchPage from './pages/proctoredLaunch';

const ToastContainer = require('react-toastify').ToastContainer;

export type AppProps = {
};
/**
 * Root application with the routing to determine the correct area of the site to navigate to, based on the url.
 * 
 * For most core pages we need to wrap them inside some higher order components (HOC) in a chain to ensure some features are present:
 * - withAuthentication - this guarantees that the Authentication workflow has happened by checking for a local session/API key. If not present then you're forced to authenticate
 * - withCandidateData - this guarantees that there is initial candidate data fetched from the server and present in the Redux store before continuing render of the real page
 */
export default class App extends React.Component<AppProps, {}> {
  private fontResize: FontResize = new FontResize();

  render() {
      /* SUBSCRIBE font-size changes */
      this.fontResize.startDetect();

      return (
          <div className="App">
              <BrowserRouter>
                  <Routes>
                      <Route path="/authenticate" element={
                            React.createElement(
                                withRouter(AuthenticatePage)
                            )
                      } />
                      <Route path="/authenticate/:token" element={
                            React.createElement(
                                withRouter(AuthenticatePage)
                            )
                      } />
                      <Route path="/" element={
                            React.createElement(
                                withRouter(
                                    withAuthentication(
                                        withCandidateData(
                                            withCms(DashboardPage)
                                        )
                                    )
                                )
                            )
                      } />
                      <Route path="/Preview/:brandingName" element={
                            React.createElement(
                                withRouter(DashboardPreview)
                            )
                      } />
                      <Route path="/sanctioned" element={
                            React.createElement(
                                withCms(SanctionedPage)
                            )
                      } />
                      <Route path="/session-timeout" element={
                            React.createElement(                                   
                                withCms(SessionTimeOutPage)                                                                                                           
                            )
                      } />
                      <Route path="/proctored-launch/:assessmentId/:languageId" element={
                            React.createElement(
                                withRouter(
                                    withAuthentication(
                                        withCandidateData(
                                            withCms(ProctoredLaunchPage)
                                        )
                                    )
                                )
                            )
                      } /> 
                  </Routes>
              </BrowserRouter>
              <BrandingWrapper />
              <ToastContainer />
          </div>
      );
  }

  componentWillUnmount() {
      /* UNSUBSCRIBE font-size changes */
      this.fontResize.stopDetect();
  }
}