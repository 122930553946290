import React from 'react';
import ReactModal from 'react-modal';
import * as Containers from '../redux-containers';
import { CmsText } from '../../misc/cms-text';
import { FormattedDate } from 'react-intl';
import { AssessmentModel, getAssessmentName } from '../../models/assessment';
import { getCandidateData } from '../../actions/candidate';
import { showReuseAssessment } from '../../actions/assessmentReuse';
import { NoJsxElement } from '../../misc/common';
import { launchAssessment } from '../../misc/assessment';
import { reuseAssessment } from '../../services/candidate-service';
import { logErrorWithAlert } from '../../misc/error';
import { CmsService } from '../../services/cms-service';
import { Actions as DataCollectionDisclosureWarningActions } from '../../actions/dataCollectionDisclosureWarning';

interface ReuseAssessmentModalStateProps {
    isOpen: boolean;
    allText: any;
    name: string;
    email: string;
    siteLanguageId: number;
    assessment: AssessmentModel | null;
}

interface ReuseAssessmentModalOwnProps {
}

interface ReuseAssessmentModalDispatchProps {
    close: () => void;
    updateCandidate: () => void;
    openDataCollectionDisclosureWarning: (callback: () => void) => void;
}

export type ReuseAssessmentModalProps = ReuseAssessmentModalStateProps & ReuseAssessmentModalOwnProps & ReuseAssessmentModalDispatchProps;

class ReuseAssessmentModal extends React.Component<ReuseAssessmentModalProps> {
    private usePreviousButtonRef: HTMLButtonElement | null;
    private cmsText: CmsText = new CmsText(this.props.allText, 'ReuseAssessmentModal', 'dashboard.launchAssessment');

    constructor(props: ReuseAssessmentModalProps) {
        super(props);
    }

    public componentDidMount() {
        // The app element should be set automatically to 'body' by react-modal but it's giving warnings at runtime saying it's
        // not been set. It's important for accessibility with screen-readers so they know what part of the page should be the
        // focus. Seem to be multiple reports online of bugs meaning it's not being set properly. The below will set it to body,
        // which is the default so if the bug is ever fixed, this won't do anything positive or negative
        ReactModal.setAppElement('body');
    }

    public render() {
        const isOpen: boolean = !!this.props.isOpen;

        const title: string = this.getText('reuse.title', 'Welcome back');

        const explanation: string = this.getText('reuse.body', 'It looks like you\'ve recently completed this {assessmentType} assessment on {reuseAssessmentDate}.'
            + ' Consider the context of your previous assessment completion.'
            + ' You may want to complete this assessment again if your circumstances have changed significantly.'
            + ' Would you like to use your previous selection?');

        setTimeout(() => this.usePreviousButtonRef && this.usePreviousButtonRef.focus(), 1);

        return (
            <ReactModal
                isOpen={isOpen}
                className={CmsService.GetSiteFontStyle() + ' reuseDialog-modal'}
                overlayClassName="modal-overlay"
                contentLabel={title + ' ' + explanation}
            >
                <div className="modal-body reuseDialog-body">
                    <h1 className="reuseDialog-title">
                        {title}
                    </h1>
                    <h2 className="reuseDialog-subtitle">
                        {this.getText('reuse.subtitle', 'You\'ve already completed the {assessmentType} assessment')}
                    </h2>
                    <div className="reuseDialog-explanation">
                        {explanation}
                    </div>
                    <table className="reuseDialog-user-details">
                        <thead>
                            <tr>
                                <th>
                                    {this.getText('reuse.name', 'Name')}
                                </th>
                                <th>
                                    {this.getText('reuse.email', 'Email')}
                                </th>
                                <th>
                                    {this.getText('reuse.assessmentDate', 'Assessment date')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {this.props.name}
                                </td>
                                <td>
                                    {this.props.email}
                                </td>
                                <td>
                                    {this.props.assessment == null ? NoJsxElement
                                        : <FormattedDate value={this.props.assessment == null ? '' : this.props.assessment.reuseAssessmentDateTime!} day="numeric" month="short" year="numeric" />}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="reuseDialog-not-you">
                        <div className="reuseDialog-not-you-img dashboard-icon-info" />
                        <span className="reuseDialog-not-you-text">{this.getText('reuse.notYou', 'If this doesn\'t look like you, please contact your assessment administrator.')}</span>
                    </div>
                </div>
                <div className="reuseDialog-footer">
                    <button onClick={this.handleCancel} className="reuseDialog-button modal-cancel" type="button">
                        {this.getText('reuse.cancelButton', 'Cancel')}
                    </button>
                    <button onClick={this.launchAssessment} className="reuseDialog-button reuseDialog-button--retake" type="button">
                        {this.getText('reuse.retakeButton', 'Retake')}
                    </button>
                    <button onClick={this.usePrevious} className="reuseDialog-button" type="button" ref={(button) => this.usePreviousButtonRef = button}>
                        {this.getText('reuse.usePreviousButton', 'Use Previous')}
                    </button>
                </div>
            </ReactModal>
        );
    }

    private getText(path: string, defaultText: string): string {
        var text = this.cmsText.get(path, defaultText);

        if (this.props.assessment) {
            if (text.indexOf('{assessmentType}') >= 0) {
                text = text.replace(
                    '{assessmentType}',
                    getAssessmentName(this.props.assessment, new CmsText(this.props.allText, 'ReuseAssessmentModal.GetAssessmentName', 'dashboard')));
            }

            if (text.indexOf('{reuseAssessmentDate}') >= 0) {
                text = text.replace(
                    '{reuseAssessmentDate}',
                    new Intl.DateTimeFormat('en-us', { day: 'numeric', month: 'short', year: 'numeric' }).format(new Date(this.props.assessment!.reuseAssessmentDateTime!)));
            }
        }
        return text;
    }

    private handleCancel = () => {
        this.props.close();
    }

    private launchAssessment: () => Promise<any> = () => {
        if (!this.props.assessment) {
            throw 'No assessment passed';
        }

        if (this.props.assessment.isProctored) {
            this.props.openDataCollectionDisclosureWarning(() => launchAssessment(this.props.assessment!, this.props.siteLanguageId, this.cmsText));
            return Promise.resolve();
        }

        return launchAssessment(this.props.assessment, this.props.siteLanguageId, this.cmsText);
    }

    private usePrevious: () => Promise<any> = () => {
        if (!this.props.assessment) {
            logErrorWithAlert(this.getText('reuse.canNotReuseError', 'Cannot reuse assessment'), 'No assessment passed');
            throw 'No assessment passed';
        }
        if (!this.props.assessment.reuseAssessmentId) {
            logErrorWithAlert(this.getText('reuse.canNotReuseError', 'Cannot reuse assessment'), 'No reusable assessment available');
            throw 'No reusable assessment available';
        }

        return reuseAssessment(this.props.assessment.assessmentId, this.props.assessment.reuseAssessmentId)
            .then(() => this.props.close())
            .then(() => this.props.updateCandidate())
            .catch((exception) => logErrorWithAlert(this.getText('reuse.canNotReuseError', 'Cannot reuse assessment'), 'Error reusing assessment', exception));
    }
}

export default Containers.createStateAndDispatchWithProps<ReuseAssessmentModalStateProps, ReuseAssessmentModalDispatchProps, ReuseAssessmentModalOwnProps>(
    ReuseAssessmentModal,
    (state) => ({
        isOpen: state.assessmentReuse.showReuseAssessmentDialog,
        assessment: state.assessmentReuse.assessment,
        allText: state.language.alltext,
        name: state.candidate.personal!.displayName,
        email: state.candidate.personal!.email,
        siteLanguageId: state.language.languageId
    }),
    (dispatch) => ({
        close: () => dispatch<any>(showReuseAssessment(false)),
        updateCandidate: () => dispatch<any>(getCandidateData()),
        openDataCollectionDisclosureWarning: (callback) => dispatch<any>(DataCollectionDisclosureWarningActions.showDataCollectionDisclosureWarning(
            {
                show: true,
                onProceedButtonClick: callback
            }
        ))
    })
);
