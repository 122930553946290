// @ts-nocheck
/**
 * Talview proctoring manager
 */
import AppConstants from "../constants/app";
import { PauseProctoring, ProctoringSession } from "../models/proctoring";
import { postObject } from './api-service';
import { logErrorSilent } from '../misc/error';

const proctoringStatuses = AppConstants.Proctoring.Statuses

export default class TalviewProctoringManager {
    status: any;
    config: any;

    constructor(config: any) {
        this.config = config;
        this.status = proctoringStatuses.STOPPED;
    }

    async init() {
        return new Promise<void>((resolve, reject) => {
            console.log("%c*** Initializing Talview proctoring manager", "color: orange");
            resolve();
        });
    }

    async start() {

        const {
            token,
            profileId,
            session,
            sessionTitle,
            sessionType,
            scriptUrl,
            additionalInstructions
        } = this.config;

        let self = this;

        return new Promise<string>((resolve, reject) => {

            // Entire code block needs to be together, can't load script first, then call tv
            (function (i, s, o, g, r, a, m) {
                i['TalviewProctor'] = r;
                i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments)
                },
                    i[r].l = 1 * new Date();
                a = s.createElement(o),
                    m = s.getElementsByTagName(o)[0];
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m)
            })(window, document, 'script', scriptUrl, 'tv');

            tv('init', token, {
                profileId: profileId,
                session: session,
                sessionTitle: sessionTitle,
                sessionType: sessionType,
                additionalInstruction: additionalInstructions,
                initCallback: (error: any, uuid: string) => {
                    if (error) {
                        reject(new Error(`Error initializing proctoring session: ${error.message || error}`));
                    } else {
                        self.initListeners();
                        self.status = proctoringStatuses.STARTED;
                        resolve(uuid);
                    }
                },
                errorCallback: (error: any, source: any) => {
                    logErrorSilent(`Error during proctoring session: ${error.message || error}, source: ${source}`);
                },
                networkDisconnectionCallback: () => {
                    logErrorSilent("Network disconnected, session paused");
                }
            });

            function stopProctoring(redirectLocationHref: string | undefined) {
                const self = this;
                ProctorClient3.stop(() => {
                    console.log('%cProctoring stopped', 'color: yellow');
                    self.status = proctoringStatuses.STOPPED;
                    if (redirectLocationHref) {
                        console.log("%cRedirecting to", "color: green", redirectLocationHref);
                        history.replaceState({}, '', redirectLocationHref);
                        history.go();
                    }
                })
            }

            function pauseProctoring(redirectLocationHref: string | undefined) {
                const self = this;

                ProctorClient3.pause();
                
                self.status = proctoringStatuses.PAUSED;

                if (redirectLocationHref) {
                    setTimeout(() => {
                        console.log('%cProctoring paused', 'color: yellow');
                        console.log("%cRedirecting to", "color: green", redirectLocationHref);
                        history.replaceState({}, '', redirectLocationHref);
                        history.go();    
                    }, 3000);    
                }                
            }

            // Create IE + others compatible event handler
            const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
            const eventer = window[eventMethod];
            const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
            const MessageEventActions = AppConstants.Proctoring.MessageEventActions;

            // Listen to message from child window
            eventer(messageEvent, (event: any) => {
                const key = event.message ? "message" : "data";
                const data = event[key];
                if (data.action) {
                    if (data.action === MessageEventActions.PAUSE) {
                        console.log("%cReceived pause proctoring message", "color: lightgreen");
                        pauseProctoring(data.redirectLocationHref);
                    }
                    if (data.action === MessageEventActions.STOP) {
                        console.log("%cReceived stop proctoring message", "color: lightgreen");
                        stopProctoring(data.redirectLocationHref);
                    }
                }
            }, false);
        });
    }

    async stop() {
        const self = this;
        const status = this.getStatus();
        return new Promise((resolve, reject) => {
            if (status !== proctoringStatuses.STOPPED) {
                ProctorClient3.stop(() => {
                    console.log("Proctoring session has been stopped.");
                    self.status = proctoringStatuses.STOPPED;
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    async pause(config: PauseProctoring) {
        return new Promise<void>((resolve, reject) => {
            try {
                window.ProctorClient3.pause(
                    "TV-PC-OTHERS",
                    config.title,
                    config.subTitle
                );

                console.log("Proctoring session has been paused.");

                this.status = proctoringStatuses.PAUSED;

                if (config.redirectLocationHref) {
                    setTimeout(function () {
                        console.log("Redirecting to ", config.redirectLocationHref);
                        history.replaceState({}, '', config.redirectLocationHref);
                        history.go();
                        resolve();
                    }, config.timeoutBeforeRedirect);
                } else {
                    resolve();
                }

            } catch (error) {
                console.error("Error pausing proctoring session", error);
                reject(error);
            }
        });
    }

    initListeners() {
        window.ProctorClient3.on('log:event:all', (data: any) => {
            console.debug("%cTalview event", "color: blue", JSON.stringify(data, null, 4));
        });
    }

    getStatus() {
        return this.status;
    }

    async saveProctoringSession(proctoringSession: ProctoringSession) {
        return postObject<ProctoringSession, string>('proctoringSession', proctoringSession, (result) => result);
    }
}