import React from 'react';
import { NoJsxElement, NullableElement } from '../../misc/common';
import {
    AssessmentModel,
    AssessmentStatus,
    getAssessmentIcon,
    getAssessmentName,
    isTimedAssessment,
} from '../../models/assessment';
import LaunchAssessment from './launch-assessment';
import { FormattedDate } from 'react-intl';
import { CmsText } from '../../misc/cms-text';
import format from 'string-template';
import { AssessmentType } from '../../models/assessment';
import LegacyDownloadReport from './legacyDownloadReport';
import { Tooltip } from 'react-tooltip';

export interface AssessmentProps {
    /**
     * Full assessment model
     */
    assessment: AssessmentModel;

    /**
     * Dashboard component text.
     */
    text: CmsText;
}

/**
 * Represents a tile to display in the dashboard representing an assessment to launch.
 * @param props The properties for this component.
 */
export default class Assessment extends React.Component<AssessmentProps> {

    /**
     * Outlines the amount of approximate time for the candidate to completed the assessment or the allowed time for a timed assessment.
     * Display the amount of extra time the candidate has been given to complete the assessment.
     * @param a The assessment to get the allowed time.
     */
    timeAllowed(a: AssessmentModel): string {
        return format(this.props.text.get('time.estimatedTime', '{time} mins'), { 'time': a.timeLengthMinutes });
    }

    /**
     * Get the description text for an assessment from cms      
     */
    getAssessmentDescription(assessmentModel: AssessmentModel): string {
        const assessmentName = getAssessmentName(assessmentModel, this.props.text);
        let description: string = '';

        switch (assessmentModel.type) {
            case AssessmentType.ElementsNumerical:
            case AssessmentType.AspectsNumerical:
                description = assessmentName + ": " + this.props.text.get('description.elementsNumerical.text', '');
                break;
            case AssessmentType.ElementsVerbal:
            case AssessmentType.AspectsVerbal:
                description = assessmentName + ": " + this.props.text.get('description.elementsVerbal.text', '');
                break;
            case AssessmentType.ElementsLogical:
                description = assessmentName + ": " + this.props.text.get('description.elementsLogical.text', '');
                break;
            case AssessmentType.AspectsChecking:
                description = assessmentName + ": " + this.props.text.get('description.checking.text', '');
                break;
            case AssessmentType.KF4D:
                description = assessmentName + ": " + this.props.text.get('description.kf4d.text', '');
                break;
            case AssessmentType.KF4DCompetencies:
            case AssessmentType.Dimensions:
            case AssessmentType.EntryLevelCompetency:
                description = assessmentName + ": " + this.props.text.get('description.kf4dCompetencies.text', '');
                break;
            case AssessmentType.Drivers:
                description = assessmentName + ": " + this.props.text.get('description.drivers.text', '');
                break;
            case AssessmentType.Traits:
                description = assessmentName + ": " + this.props.text.get('description.traits.text', '');
                break;
            case AssessmentType.Experiences:
                description = assessmentName + ": " + this.props.text.get('description.experiences.text', '');
                break;
            case AssessmentType.Preferences:
                description = assessmentName + ": " + this.props.text.get('description.preferences.text', '');
                break;
            case AssessmentType.PlayerTest:
                if (assessmentModel.description) {
                   description = assessmentName + ": " + assessmentModel.description;
                }               
                break;
            case AssessmentType.AbstractReasoning:
                description = assessmentName + ": " + this.props.text.get('description.abstractReasoning.text', '');
                break;
            case AssessmentType.TechnicalSkillsInventory:
                description = assessmentName + ": " + this.props.text.get('description.technicalSkillsInventory.text', '');
                break;
            case AssessmentType.VirtualRecruiter:
                description = assessmentName + ": " + this.props.text.get('description.virtualRecruiter.text', '');
                break;
            case AssessmentType.InclusiveLeaderSjt:
                description = assessmentName + ": " + this.props.text.get('description.inclusiveLeaderSjt.text', 'cms not found');
                break;
            default:
                description = '';
                break;
        }

        return description;
    }

    extraTimeText(a: AssessmentModel): string {
        let extraTimeText: string = '';
        if (isTimedAssessment(a.type)) {
            if (a.timeExtraMinutes > 0) {
                extraTimeText = format(this.props.text.get('time.extraTime', 'Time added: {time} mins'), { 'time': a.timeExtraMinutes });
            }
        }
        return extraTimeText;
    }

    /**
     * A list of equipment required to complete the assessment e.g. calculator / notepad.
     * @param a The assessment to get the equipment list for.
     */
    equipmentRequired(a: AssessmentModel): JSX.Element[] {
        let equipment: JSX.Element[] = [];
        if (a.needsCalculator) {
            equipment.push(<span key="calculator" className="dashboard-icon dashboard-icon-calc" title={this.props.text.get('equipment.calculator', 'Calculator required')} />);
        }

        if (a.needsNotepad) {
            equipment.push(<span key="notepad" className="dashboard-icon dashboard-icon-pen" title={this.props.text.get('equipment.notepad', 'Notepad required')} />);
        }

        return equipment;
    }

    /**
     * Display a proctored badge if the assessment is proctored.
     * @param a The assessment to get the proctored badge for.
     */
    proctoredBadge(a: AssessmentModel): JSX.Element {
        if (a.isProctored) {
            return <span className="dashboard-icon badge badge-proctored">
                { this.props.text.get('proctoring.badgeLabel', 'Proctored') }

                <span 
                    className="dashboard-icon dashboard-icon-info-badge" 
                    data-tooltip-id="proctored-tooltip" 
                    data-tooltip-place="top"
                />
                
                <Tooltip 
                    id="proctored-tooltip"
                    className="badge-proctored-tooltip"
                    classNameArrow="badge-proctored-tooltip-arrow"
                    border="1px solid rgba(145, 145, 145, 0.3)"
                    data-html={ true }
                >
                    { this.props.text.get('proctoring.badgeTooltipText', 'Proctored assessment') }
                </Tooltip>
            </span>;
        }
        return NoJsxElement;
    }

    /**
     * Display the status of the assessment or the date completed.
     * @param a The assessment to get status for.
     */
    assessmentStatus(a: AssessmentModel): JSX.Element {
        switch (a.status) {
            case AssessmentStatus.Completed:
                return (
                    <p className="assessment-container-date-text">
                        { this.props.text.get('status.completed', 'Completed') } &nbsp;<FormattedDate value={a.completed!} day="numeric" month="long" year="numeric" />
                    </p>
                );
            case AssessmentStatus.InProgress:
                if (a.isLocked) {
                    return this.getExpiredAssessmentStatus(a);
                } else {
                    return (
                        <p>
                            { this.props.text.get('status.inProgress', 'In Progress') }
                        </p>
                    );
                }
            default:
                if (a.isLocked) {
                    return this.getExpiredAssessmentStatus(a);
                } else {
                    return (
                        <p>
                            { this.props.text.get('status.notStarted', 'Not started') }
                        </p>
                    );
                }
        }
    }

    getExpiredAssessmentStatus(a: AssessmentModel): JSX.Element {
        let formattedDate = a.latestProjectDeadline != null
            ? <FormattedDate value={ a.latestProjectDeadline! } day="numeric" month="long" year="numeric" />
            : null;
        return (
            <p className="assessment-container-date-text">
                { this.props.text.get('status.expired', 'Expired') } &nbsp;{ formattedDate }
            </p>
        );
    }

    /**
     * 
     * @param a The assessment to complete an action for.
     */
    action(a: AssessmentModel): JSX.Element {
        switch (a.status) {
            case AssessmentStatus.NotStarted:
            case AssessmentStatus.InProgress:
                return <LaunchAssessment assessment={a} assessmentName={ getAssessmentName(a, this.props.text) }/>;
            case AssessmentStatus.Completed:
                return <LegacyDownloadReport assessment={a} />;
            default:
                return NoJsxElement;
        }
    }

    assessmentIcon(a: AssessmentModel): JSX.Element {
        if (!isTimedAssessment(a.type)) {
            return NoJsxElement;
        }

        let iconClass: string = '';
        if (a.timeExtraMinutes > 0) {
            iconClass = 'dashboard-icon-time-added';
        } else {
            iconClass = 'dashboard-icon-time-limit';
        }

        return <span className={'dashboard-icon' + ' ' + iconClass} />;
    }

    /**
     * Time states for an assessment.
     * @param a The assessment to complete an action for.
     */
    timeState(a: AssessmentModel): NullableElement {
        if (a.status === AssessmentStatus.Completed || a.isLocked) {
            return NoJsxElement;
        }

        return (
            <span className="assessment-container-time">
                {this.assessmentIcon(a)}
                <p>{ this.timeAllowed(a) }</p>
                <p className="assessment-container-time-added">{ this.extraTimeText(a) }</p>
            </span>
        );
    }

    progress(a: AssessmentModel): NullableElement {

        switch (a.status) {
            case AssessmentStatus.Completed:
                return (
                    <span className="assessment-container-date">
                        <span className="dashboard-icon dashboard-icon-complete" />
                        { this.assessmentStatus(a) }
                    </span>
                );
            case AssessmentStatus.NotStarted:
            case AssessmentStatus.InProgress:
                if (a.isLocked) {
                    return (
                        <span className="assessment-container-date">
                            <span className="dashboard-icon dashboard-icon-expired" />
                            { this.assessmentStatus(a) }
                        </span>
                    );
                }

                return (
                    <span className="assessment-container-progress">
                        <span className="assessment-container-progress-container">
                            { this.assessmentStatus(a) }
                        </span>
                    </span>
                );
            default:
                return NoJsxElement;
        }
    }

    getAssessmentAriaDescribedId(value: AssessmentModel): string {
        return `incomplete-assessments-grid-title-column-assessment_${value.assessmentId}`;
    }

    render() {
        const a: AssessmentModel = this.props.assessment;
        const ariaDescribedId: string = this.getAssessmentAriaDescribedId(a);

        return (
            <tr className="assessment-container">
                <td className="assessment-container-item" aria-describedby={ariaDescribedId}>
                    <span className={getAssessmentIcon(a.type)} />
                    <div>
                        <h3 className="assessment-container-title" id={ariaDescribedId}>
                            {getAssessmentName(a, this.props.text)}{this.proctoredBadge(a)}{this.equipmentRequired(a)}
                        </h3>
                        <p className="assessment-container-description">
                            {this.getAssessmentDescription(a)}
                        </p>
                    </div>
                </td>
                <td className="assessment-container-item">
                    { this.timeState(a) }
                </td>
                <td className="assessment-container-item assessment-container-item-progress">
                    { this.progress(a) }
                </td>
                <td className="assessment-container-column assessment-container-action">
                    { this.action(a) }
                </td>
            </tr>
        );
    }
}
